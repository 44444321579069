<template>
  <v-container id="order-tables" fluid tag="section">
    <base-material-card icon="mdi-clipboard-text" :title="role===UserRole.Admin?$t('order.title'):$t('order.userTitle')" class="px-5 py-3">
      <v-col class="text-right">
        <v-dialog v-model="dialog_form" max-width="500px" @click:outside="close">
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t("order.orderDesc") }}</span>
            </v-card-title>
              <v-card-text>
                {{ item_detail }}
              </v-card-text>
              <v-card-text v-if="orderData.memo!=null&&orderData.memo!=''" class="pt-0 grey--text">
                {{ $t('common.memo') }}：{{ orderData.memo }}
              </v-card-text>
              <v-divider />
              <v-card-text class="py-0">
                <v-list-item three-line class="px-0">
                  <v-list-item-content>
                    <v-list-item-title class="text-h4">{{ $t('invoice.title') }}</v-list-item-title>
                    <v-list-item-subtitle class="text-h5">
                      {{ $t('invoice.invoiceStatus') }}：{{ invoiceData.status|getText(InvoiceStatusItems)|Translate }}
                      <template v-if="invoiceData.status==InvoiceStatus.Init">
                        <v-btn small color="primary" :disabled="loading" @click="touchInvoice()">{{ $t('invoice.touchInvoice') }}</v-btn>
                      </template>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="text-h5">
                      {{ $t('invoice.invoiceNum') }}：{{ invoiceData.invoice_number }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="text-h5">
                      <template v-if="invoiceData.touch_time">
                        {{ $t('invoice.touchTime') }}：
                        {{ TransDate(invoiceData.touch_time) }}
                      </template>
                      <template v-else>
                        {{ $t('common.lastModifyTime') }}：
                        {{ TransDate(invoiceData.create_time) }}
                      </template>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{
                $t("common.confirm")
                }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_edit" max-width="500px" @click:outside="editClose">
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t("order.edit") }}</span>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-0">
              <v-checkbox
                v-model="orderData.is_canceled"
                :label="$t('order.cancel')"
              ></v-checkbox>
              <v-text-field
                v-model="orderData.memo"
                :label="$t('common.memo')"
              >
              </v-text-field>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="updateOrder">
                {{ $t("common.save") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="editClose">
                {{ $t("common.cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <dialog-message
          :dialog_message="dialog_message"
          :message="message"
        ></dialog-message>
      </v-col>
      <v-data-table
        :headers="role===UserRole.Admin?headers:userHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:item.is_paid="{ item }">
          <span v-if="item.is_canceled">{{ $t('order.canceled') }}</span>
          <span v-else>{{ TransBoolean(item.is_paid) }}</span>
        </template>
        <template v-slot:item.order_time="{ item }">
          <span>{{ TransDate(item.order_time) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-flex>
            <v-btn
              color="primary"
              class="mx-2"
              small
              @click="getInvoice(item)"
            >{{ $t("order.orderDesc") }}</v-btn>
            <v-btn
              color="orange"
              class="mx-2"
              small
              @click="editInvoice(item)"
            >{{ $t("order.edit") }}</v-btn>
          </v-flex>
        </template>
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            dense
            :show-first-last-page="footerProps.showFirstLastPage"
            :show-current-page="footerProps.showCurrentPage"
            :first-icon="footerProps.firstIcon"
            :last-icon="footerProps.lastIcon"
            :prev-icon="footerProps.prevIcon"
            :next-icon="footerProps.nextIcon"
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            :itemsPerPageOptions="footerProps.itemsPerPageOptions"
          />
        </template>
        <template slot="no-data">
          <div>
            {{ $t('common.noData') }}
          </div>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { UserRole, InvoiceStatus, InvoiceStatusItems } from "@/definition"
export default {
  data: vm => ({
    UserRole: UserRole,
    InvoiceStatus: InvoiceStatus,
    InvoiceStatusItems: InvoiceStatusItems,
    dialog_message: false,
    valid: true,
    items: [],
    totalItems: 0,
    loading: false,
    dialog_form: false,
    dialog_edit: false,
    orderData:{
      is_canceled: false,
      memo: null,
    },
    options: {
        sortBy: ['-order_time']
    },
    item_detail: '',
    message: {
      title: "",
      text: ""
    },
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [5, 10, 20],
    },
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("order.ID"),
        value: "id"
      },
      {
        sortable: false,
        text: vm.$i18n.t("order.SpgatewaySlug"),
        value: "SpgatewaySlug"
      },
      {
        sortable: false,
        text: vm.$i18n.t("order.total"),
        value: "total"
      },
      {
        sortable: false,
        text: vm.$i18n.t("order.isPaid"),
        value: "is_paid"
      },
      {
        sortable: false,
        text: vm.$i18n.t("order.username"),
        value: "user.username"
      },
      {
        sortable: false,
        text: vm.$i18n.t("order.company"),
        value: "company.name"
      },
      {
        sortable: false,
        text: vm.$i18n.t("order.orderTime"),
        value: "order_time"
      },
      {
        sortable: false,
        text: '',
        value: "actions"
      }
    ],
    userHeaders: [
        {
            sortable: false,
            text: vm.$i18n.t("order.ID"),
            value: "id"
        },
        {
            sortable: false,
            text: vm.$i18n.t("order.orderDesc"),
            value: "order_desc"
        },
        {
            sortable: false,
            text: vm.$i18n.t("order.total"),
            value: "total"
        },
        {
            sortable: false,
            text: vm.$i18n.t("order.orderTime"),
            value: "order_time"
        },
    ],
    item: {
      name: "",
      company_id: "",
      phone: "",
      max_urban_renewal: 0,
      max_issue: 0,
      admins: [],
      users: [],
    },
    invoiceData: {},
    orderID: null,
  }),
  computed: {
    ...mapState(["token", "role"])
  },
  methods: {
    ...mapMutations(["setTitle"]),
    close() {
      this.dialog_form = false;
      this.loading = false;
    },
    editClose(){
      this.dialog_edit = false;
      this.loading = false;
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/order/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
        params: {
            page: page,
            page_size: itemsPerPage,
        }
      };
      // order
        if (Array.isArray(sortBy) && sortBy.length > 0) {
          let array = [];
          let item = "";
          for (let i = 0; i < sortBy.length; i++) {
            let sortStr = sortBy[i].replace(".", "__");
            if (sortDesc[i]) {
              item = sortStr;
            } else {
              item = "-" + sortStr;
            }
            array.push(item);
          }
          config['params']["ordering"] = array.join();
        }

      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    getInvoice(order){
      this.invoiceData = {}
      this.orderID = order.id
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/order/" + order.id + "/getInvoiceInfo/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
      };

      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          // currentObj.setCompany(response.data);
          console.log(response.data)
          currentObj.invoiceData = response.data
          currentObj.showDetail(order)
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    editInvoice(order){
      this.orderID = order.id
      this.dialog_edit = true
      this.orderData.is_canceled = order.is_canceled
      this.orderData.memo = order.memo
    },
    updateOrder(){
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/order/" + this.orderID + "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
      };

      let data = {
        is_canceled: this.orderData.is_canceled,
        memo: this.orderData.memo
      }
      console.log(data)

      this.axios
        .patch(url, data, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.dialog_edit = false;
          alert("送出成功");
          currentObj.getApi();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = info.count;
      this.items = data.results;
    },
    setCompany(data){
      this.item.name = data.company.name;
      this.item.company_id = data.company.company_id;
      this.item.phone = data.company.phone;
      this.item.max_urban_renewal = data.company.max_urban_renewal;
      this.item.max_issue = data.company.max_issue;
      this.item.admins = data.admin;
      this.item.users = data.user;
    },
    showDetail(order) {
      this.dialog_form = true;
      this.item_detail = order.order_desc
      this.orderData.memo = order.memo
    },
    TransBoolean(boolean){
        if(boolean){
            return this.$i18n.t('order.paid.true')
        } else {
            return this.$i18n.t('order.paid.false')
        }
    },
    touchInvoice(){
      if(!confirm(
          this.$i18n.t('common.confirm') + this.$i18n.t('invoice.touchInvoice')+ "？"
        )
      ) {
        return;
      }
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/order/" + this.orderID + "/generateInvoice/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token
        }
      };
      let data = {}
      this.axios
        .post(url, data, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.dialog_form = false;
          alert("送出成功");
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  mounted() {
    let title
    if(this.role===this.UserRole.Admin){
        title = this.$i18n.t("order.title")
    } else {
        title = this.$i18n.t("order.userTitle")
    }
    this.setTitle(title)
    if(this.$route.query.failed){
      this.message.title = this.$t("order.failed");
      this.dialog_message = !this.dialog_message;
    }
  }
};
</script>

<style lang='sass' scope>
.v-form
  width: 100%
  .v-btn__content
    a
      color: #FFFFFF
      text-decoration: none

</style>